import React from 'react';

import Layout from 'components/layout';
import Hero from 'components/hero';
import Hightlight from 'components/Highlight';
import SEO from 'components/seo';

const IndexPage = props => (
  <Layout>
    <SEO title="Byvejret - Danmarks bedste vejr-app" />
    <Hero data={props.data} />

    <Hightlight data={props.data} />

    {/* <div>
      <div className="flex justify-between">
        <Feature />
        <Feature />
        <Feature />
      </div>
    </div> */}

    {/* <div>
      <div className="flex justify-between items-center px-16 py-8">
        <Platform />
        <Platform />
        <Platform />
        <Platform />
      </div>
    </div> */}

    {/* <Info /> */}
    {/* <Banner /> */}
  </Layout>
);

export const query = graphql`
  query {
    highlightOne: file(relativePath: { eq: "highlight-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    highlightTwo: file(relativePath: { eq: "highlight-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    highlightThree: file(relativePath: { eq: "highlight-03.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    highlightFour: file(relativePath: { eq: "highlight-04.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    heroOne: file(relativePath: { eq: "hero-01.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroTwo: file(relativePath: { eq: "hero-02.jpg" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 1920) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    heroPhoneOne: file(relativePath: { eq: "hero-phone-01.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    heroPhoneTwo: file(relativePath: { eq: "hero-phone-02.png" }) {
      childImageSharp {
        fluid(maxWidth: 450) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;

export default IndexPage;
