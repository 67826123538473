import React from 'react';
import Img from 'gatsby-image';

import Tablet from 'assets/svg/tablet.svg';
import Paint from 'assets/svg/paint.svg';
import DarkMode from 'assets/svg/dark-mode.svg';
import Weather from 'assets/svg/weather.svg';
import Local from 'assets/svg/pin.svg';
import Schedule from 'assets/svg/schedule.svg';

import Switch from './switch';
import ThemeContext from '../context/ThemeContext';

const Highlight = ({ data }) => {
  const { highlightOne, highlightTwo, highlightThree, highlightFour } = data;

  return (
    <ThemeContext.Consumer>
      {theme => (
        <div className="py-16 bg-gray-50 overflow-hidden md:py-20 lg:py-24">
          <div className="relative max-w-xl mx-auto px-6 lg:px-8 lg:max-w-screen-xl">
            <svg
              className="hidden lg:block absolute left-full transform -translate-x-1/2 -translate-y-1/4"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="svg-pattern-squares-1"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="dot-pattern"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#svg-pattern-squares-1)"
              ></rect>
            </svg>

            <div className="relative">
              <h3 className="heading text-center text-3xl leading-8 font-extrabold tracking-tightsm:text-4xl sm:leading-10">
                Din Lokale Vejr App
              </h3>
              <p className="mt-4 max-w-3xl mx-auto text-xl leading-7 text-black-300 text-center">
                Byvejret er din kilde til vejrinformation, både lokalt,
                nationalt og internationalt. Takket være vores
                samarbejdspartnere, kan vi levere detaljeret information for
                hele verdenen. Så du kan være forberedt når du træder ud af
                døren, uanset hvor i verden du befinder dig.
              </p>
            </div>

            <div className="relative mt-12 lg:mt-20 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative">
                <h4 className="heading text-2xl leading-8 font-extrabold tracking-tight sm:text-3xl sm:leading-9 text-center sm:text-left">
                  Byvejret 2020
                </h4>
                <p className="mt-3 text-lg leading-7 text-black-300 text-center sm:text-left">
                  Vi er glade for at kunne præsentere en opdateret version af
                  Byvejret. Vi har selvfølgelig beholdt samme gode
                  funktionalitet, men har givet den en visuel overhaling, samt
                  tilføjet nogle nye funktioner.
                </p>

                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <Paint className="w-5" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="heading text-lg leading-6 font-medium">
                          Opfrisket Design
                        </h5>
                        <p className="mt-2 text-base leading-6 text-black-300">
                          Vi har haft rengøringsmidler fremme, og har givet hele
                          Byvejret en opfriskning, så den nu fremstår knivskarpt
                          og som ny.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <Tablet className="w-5" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="heading text-lg leading-6 font-medium">
                          iPad & Android Support
                        </h5>
                        <p className="mt-2 text-base leading-6 text-black-300">
                          Byvejret understøtter nu iPad og Android, så byvejret
                          ser fantastisk ud, uanset hvilken skærm du bruger.
                        </p>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                          <DarkMode className="w-5" />
                        </div>
                      </div>
                      <div className="ml-4">
                        <h5 className="heading text-lg leading-6 font-medium">
                          Dark Mode
                        </h5>
                        <p className="mt-2 text-base leading-6 text-black-300">
                          Du kan nu få Byvejret i mørk tilstand, så den kan
                          matche dine andre apps. Slå det til inde under
                          indstillinger.
                          <div className="flex items-center mt-4 ml-1">
                            <Switch
                              id="darkmode-switch--highlight"
                              className="transform scale-125"
                              onChange={e => theme.toggleDark()}
                              checked={theme.dark}
                            />
                            <span className="pl-4 text-xs text-right text-white font-medium">
                              {theme.dark ? (
                                <span>
                                  Dark mode
                                  <span className="text-yellow-400 ml-1">
                                    ⚡️
                                  </span>
                                </span>
                              ) : (
                                <span className="text-black-600">
                                  Dark mode
                                  <span className="text-yellow-400 ml-1">
                                    ⚡️
                                  </span>
                                </span>
                              )}
                            </span>
                          </div>
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="mt-10 -mx-4 relative lg:mt-0">
                <svg
                  className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                  width="784"
                  height="404"
                  fill="none"
                  viewBox="0 0 784 404"
                >
                  <defs>
                    <pattern
                      id="svg-pattern-squares-2"
                      x="0"
                      y="0"
                      width="20"
                      height="20"
                      patternUnits="userSpaceOnUse"
                    >
                      <rect
                        x="0"
                        y="0"
                        width="4"
                        height="4"
                        className="dot-pattern"
                        fill="currentColor"
                      ></rect>
                    </pattern>
                  </defs>
                  <rect
                    width="784"
                    height="404"
                    fill="url(#svg-pattern-squares-2)"
                  ></rect>
                </svg>
                <Img
                  className="relative mx-4 my-16 sm:my-8 sm:mx-auto"
                  style={{ maxWidth: '450px' }}
                  fluid={
                    theme.dark
                      ? highlightTwo.childImageSharp.fluid
                      : highlightOne.childImageSharp.fluid
                  }
                />
              </div>
            </div>

            <svg
              className="hidden lg:block absolute right-full transform translate-x-1/2 translate-y-12"
              width="404"
              height="784"
              fill="none"
              viewBox="0 0 404 784"
            >
              <defs>
                <pattern
                  id="svg-pattern-squares-3"
                  x="0"
                  y="0"
                  width="20"
                  height="20"
                  patternUnits="userSpaceOnUse"
                >
                  <rect
                    x="0"
                    y="0"
                    width="4"
                    height="4"
                    className="dot-pattern"
                    fill="currentColor"
                  ></rect>
                </pattern>
              </defs>
              <rect
                width="404"
                height="784"
                fill="url(#svg-pattern-squares-3)"
              ></rect>
            </svg>

            <div className="relative mt-12 sm:mt-16 lg:mt-24">
              <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-8 lg:items-center">
                <div className="lg:col-start-2">
                  <h4 className="heading text-2xl leading-8 font-extrabold tracking-tight sm:text-3xl sm:leading-9 text-center sm:text-left">
                    Danmarks Bedste Vejr App
                  </h4>
                  <p className="mt-3 text-lg leading-7 text-black-300 text-center sm:text-left">
                    Hvad enten man er vejr entusiast eller bare alminde
                    nysgerrig omkring dagens vejr, kan man få informationerne
                    præsenteret på en nem og overskuelig måde.
                  </p>

                  <ul className="mt-10">
                    <li>
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <Weather className="w-5" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="heading text-lg leading-6 font-medium">
                            Detaljeret Vejrdata
                          </h5>
                          <p className="mt-2 text-base leading-6 text-black-300">
                            Få en detaljeret oversigt over sol op- og
                            nedgangstider, tryk, nedbør, vindstyrke og retning,
                            alt sammen præsenteret enkelt og simpelt.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <Local className="w-5" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="heading text-lg leading-6 font-medium">
                            Lokal Vejrdata
                          </h5>
                          <p className="mt-2 text-base leading-6 text-black-300">
                            I Byvejret får du Danmarks bedste lokale vejrdata,
                            takket være lokale målestationer spredt ud over hele
                            landet.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="mt-10">
                      <div className="flex">
                        <div className="flex-shrink-0">
                          <div className="flex items-center justify-center h-12 w-12 rounded-md bg-blue-500 text-white">
                            <Schedule className="w-5" />
                          </div>
                        </div>
                        <div className="ml-4">
                          <h5 className="heading text-lg leading-6 font-medium">
                            Planlæg forud
                          </h5>
                          <p className="mt-2 text-base leading-6 text-black-300">
                            Byvejret giver dig muligheden for at hurtigt få et
                            visuelt overblik for vind, nedbør og temperatur de
                            kommende dage.
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>

                <div className="mt-10 -mx-4 relative lg:mt-0 lg:col-start-1">
                  <svg
                    className="absolute left-1/2 transform -translate-x-1/2 translate-y-16 lg:hidden"
                    width="784"
                    height="404"
                    fill="none"
                    viewBox="0 0 784 404"
                  >
                    <defs>
                      <pattern
                        id="svg-pattern-squares-4"
                        x="0"
                        y="0"
                        width="20"
                        height="20"
                        patternUnits="userSpaceOnUse"
                      >
                        <rect
                          x="0"
                          y="0"
                          width="4"
                          height="4"
                          className="dot-pattern"
                          fill="currentColor"
                        ></rect>
                      </pattern>
                    </defs>
                    <rect
                      width="784"
                      height="404"
                      fill="url(#svg-pattern-squares-4)"
                    ></rect>
                  </svg>
                  <Img
                    className="relative mx-4 my-16 sm:my-8 sm:mx-auto"
                    style={{ maxWidth: '450px' }}
                    fluid={
                      theme.dark
                        ? highlightFour.childImageSharp.fluid
                        : highlightThree.childImageSharp.fluid
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </ThemeContext.Consumer>
  );
};

export default Highlight;
