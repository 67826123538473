import React from 'react';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';

import Apple from 'assets/svg/apple.svg';
import Google from 'assets/svg/google.svg';

import ThemeContext from '../context/ThemeContext';

const Hero = ({ data }) => {
  const { heroOne, heroTwo, heroPhoneOne, heroPhoneTwo } = data;
  return (
    <ThemeContext.Consumer>
      {theme => (
        <BackgroundImage
          Tag="div"
          fluid={
            theme.dark
              ? heroTwo.childImageSharp.fluid
              : heroOne.childImageSharp.fluid
          }
          className="hero relative px-6 sm:px-16 py-8 h-content-sm sm:h-content-md text-white overflow-hidden"
        >
          <div className="relative lg:max-w-screen-xl mx-auto">
            <div className="relative z-10 flex h-full">
              <div className="text-center sm:text-left mx-auto sm:mx-0">
                <div className="mt-4 sm:mt-32 mb-4 text-3xl sm:text-6xl">
                  <h1 className="text-4xl sm:text-6xl">Byvejret</h1>
                </div>
                <p className="mb-6 max-w-sm">
                  Den hurtigste måde at få et overblik over vejret - uanset hvor
                  i verden du befinder dig.
                </p>
                {/* <Button title="Hent app" /> */}
                <div className="text-center sm:text-left">
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://apps.apple.com/dk/app/byvejret/id357576498"
                  >
                    <Apple className="inline-block h-10 sm:h-12 mr-4" />
                  </a>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://play.google.com/store/apps/details?id=dk.woerk.byvejret&hl=da"
                  >
                    <Google className="inline-block h-10 sm:h-12" />
                  </a>
                </div>
              </div>
            </div>
            <figure className="absolute bottom-0 right-0 w-full xl:max-w-screen-lg mx-auto">
              <Img
                className="ml-auto opacity-0 sm:opacity-100 -bottom-sm  mx-auto sm:mr-16 max-w-xs"
                fluid={
                  theme.dark
                    ? heroPhoneTwo.childImageSharp.fluid
                    : heroPhoneOne.childImageSharp.fluid
                }
              />
              {/* <img src="https://lunarway.com/static/a20ae910-81c2-46cd-a4dc-a984b632c65b_lunar-private-hero-optimized-2b2944c0893983dd098c17509f41bf3e.png" /> */}
            </figure>
          </div>
        </BackgroundImage>
      )}
    </ThemeContext.Consumer>
  );
};

Hero.propTypes = {};

Hero.defaultProps = {};

export default Hero;
